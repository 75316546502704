import {useMemo, useState} from 'react';
import {ClickAwayListener, Fade, Paper, Popper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {SelectButton} from 'src/componentsNext/select/SelectButton';

import {DropdownOptions} from './DropdownOptions';

const useStyles = makeStyles(theme => ({
  popper: {
    // zIndex: theme.zIndex.appBar - 2,
    zIndex: theme.zIndex.appBar - 2,
    width: '100%'
  },
  popperOpen: {
    zIndex: theme.zIndex.appBar - 2
  },
  paper: {
    marginTop: -2,
    padding: theme.spacing(1, 0),
    background: theme.palette.backgrounds.input,
    backdropFilter: 'blur(2px)',
    borderRadius: '0 0 4px 4px',
    color: theme.palette.common.white
  },
  dropdownComponent: {
    zIndex: theme.zIndex.appBar - 1
  }
}));

export const Dropdown = ({
  disabled = false,
  options = [],
  value,
  noOptionsSelectedLabel,
  allOptionsSelectedLabel,
  loading = false,
  buttonLabel,
  error,
  onChange,
  className = '',
  dropdownComponentProps,
  DropdownComponent = SelectButton,
  content: ContentComponent = DropdownOptions,
  PopperProps,
  PaperProps
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const selectedOptions = useMemo(() => {
    if (Array.isArray(value)) {
      return options.filter(option => value.includes(option.value));
    }

    return options.find(option => option.value === value);
  }, [value, options]);

  const label = useMemo(() => {
    if (selectedOptions) {
      if (Array.isArray(selectedOptions)) {
        if (
          options.length === selectedOptions.length &&
          allOptionsSelectedLabel
        ) {
          return allOptionsSelectedLabel;
        }

        if (selectedOptions.length === 0 && noOptionsSelectedLabel) {
          return noOptionsSelectedLabel;
        }

        return selectedOptions.reduce(
          (str, option) => `${str}${str.length ? ', ' : ''}${option.label}`,
          ''
        );
      }

      return selectedOptions.label;
    }
  }, [
    allOptionsSelectedLabel,
    noOptionsSelectedLabel,
    options.length,
    selectedOptions
  ]);

  const handleClick = e => {
    // e.stopPropagation();
    e.preventDefault();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleChange = value => {
    onChange(value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{position: 'relative'}} className={className}>
      <DropdownComponent
        {...dropdownComponentProps}
        disabled={disabled}
        open={open}
        label={buttonLabel || label}
        loading={loading}
        error={error}
        onClick={handleClick}
        className={clsx(
          classes.dropdownComponent,
          // eslint-disable-next-line react/prop-types
          dropdownComponentProps?.className
        )}
      />
      <Popper
        transition
        disablePortal
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        // keepMounted
        className={clsx(
          // eslint-disable-next-line react/prop-types
          PaperProps?.className,
          classes.popper,
          open && classes.popperOpen
        )}
        {...PopperProps}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              square
              size='small'
              classes={{root: classes.paper}}
              {...PaperProps}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <ContentComponent
                    isDropdown
                    options={options}
                    value={value}
                    loading={loading}
                    singleFilterSelected={selectedOptions}
                    onChange={disabled ? () => {} : handleChange}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

Dropdown.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.string,
  noOptionsSelectedLabel: PropTypes.string,
  allOptionsSelectedLabel: PropTypes.string,
  loading: PropTypes.bool,
  buttonLabel: PropTypes.node,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  dropdownComponentProps: PropTypes.shape({}),
  DropdownComponent: PropTypes.node,
  content: PropTypes.node,
  PopperProps: PropTypes.shape({}),
  PaperProps: PropTypes.shape({})
};
